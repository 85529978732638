.unsubscribe-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 25px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 999999; /* Very high z-index */
  
  }
  
  .unsubscribe-container h2 {
    text-align: center;
    margin-bottom: 25px;
    color: #333;
  }
  
  .email-display {
    text-align: center;
    margin-bottom: 30px;
    color: #555;
    font-style: italic;
  }
  
  .preferences-list {
    margin-bottom: 25px;
  }
  
  .preference-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  
  .preference-name {
    font-size: 16px;
    color: #333;
  }
  
  /* Toggle Switch Styling */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  .save-button, .retry-button {
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .save-button:hover, .retry-button:hover {
    background-color: #0b7dda;
  }
  
  .save-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .test-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .test-button:hover {
    background-color: #5a6268;
  }
  
  .success-message {
    background-color: #dff0d8;
    color: #3c763d;
    padding: 15px;
    margin: 20px 0;
    border-radius: 4px;
    text-align: center;
  }
  
  .status-message {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    color: #555;
    border-radius: 4px;
    text-align: center;
  }
  
  .error {
    text-align: center;
    color: #721c24;
    background-color: #f8d7da;
    padding: 20px;
    border-radius: 4px;
  }
  
  /* Loading Spinner */
  .loading {
    text-align: center;
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #2196F3;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .email-input-container {
    text-align: center;
    max-width: 90%;

  }
  
  .email-form {
    display: flex;
    max-width: 95%;
    margin: 20px auto;
  }
  
  .email-form input {
    max-width: 80%;
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    font-size: 16px;
    color: #333; /* Add this line to fix the text color */
    background-color: #fff; /* Ensure background is white */
  }
    
  .arrow-button {
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    padding: 10px 16px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .arrow-button:hover {
    background-color: #0b7dda;
  }