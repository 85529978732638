.gold-button {
    background-color: rgb(212 233 98);
    color: black;
}

.overlay-blur {
    filter: blur(40px);
    transition: filter 0.3s ease;
}

.black-button {
    background-color: black;
    color: rgb(212 233 98);
    border: 2px solid rgb(212 233 98);
}

.black-button:hover {
    background-color: rgb(212 233 98);
    color: black;
}

.gold-button:hover {
    background-color: black;
    color: rgb(212 233 98);
    border: 2px solid rgb(212 233 98);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fade-in 1s ease-in-out;
}

.app {
    color: #fff;
    font-family: Arial, sans-serif;
}

html {
    --cursor-color: #cccc08;
    --cursor-border: #22c700;
}

html.dark-mode {
    --cursor-color: #fff;
}

html {
    height: auto;
}

body {
    height: 100%;
}

body:before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url('./assets/images/backgrounds/background-landscape.png')
        no-repeat center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

/* body {
  background-image: url('./assets/images/backgrounds/webp/intertennis_landscape_darkbg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
} */
/* background-size: 25% 25%; */
/* This will stretch the image to fill the container */
/* background-color: #ffffff; */
/* white with 70% opacity */

#layer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.15);
}

.video-container {
    position: relative;
    width: 100vh;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.background-video-mobile {
    display: none;
}

@media (max-width: 768px) {
    .app {
        color: #111;
        /* font-family: Arial, sans-serif; */
    }

    .background-video {
        display: none;
    }

    .background-video-mobile {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        z-index: 1000;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .video-container {
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 177.78%;
        /* This will give you a 16:9 aspect ratio. Adjust as needed */
        overflow: hidden;
        /* Hide the parts of the video outside the container */
    }
}

.download-section {
    background-color: white;
    @apply flex justify-center items-center gap-5 flex-wrap md:min-h-[15vh] min-h-[10vh] py-4;
}

.google-play-button img {
    width: 240px;
    height: auto;
}

.app-store-button img {
    width: 220px;
    height: auto;
    margin-bottom: 0;
}

.google-play-image:hover,
.app-store-image:hover {
    filter: drop-shadow(2px 5px 10px black);
    transition: all 0.1s;
    transform: scale(1.1);
}

.google-play-button p {
    text-align: center;
    margin-top: 0px;
}

.app-store-button p {
    text-align: center;
    margin-top: 0px;
}

.features-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.facebook-logo:hover {
    filter: drop-shadow(2px 5px 6px black);
    transition: all 0.1s;
    transform: scale(1.1);
}

.instagram-logo:hover {
    filter: drop-shadow(2px 5px 6px black);
    transition: all 0.1s;
    transform: scale(1.1);
}

.youtube-logo:hover {
    filter: drop-shadow(2px 5px 6px black);
    transition: all 0.1s;
    transform: scale(1.1);
}

.feature-row {
    display: flex;
    height: 50vh;
    gap: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    margin-top: 10%;
    margin-bottom: 5%;
}

.feature-text {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    transition:
        transform 0.3s ease,
        opacity 0.3s ease;
}

.feature-text h3 {
    transition: color 0.3s ease;
    cursor: pointer;
}

.feature-image {
    height: 100%;
    width: auto;
    -o-object-fit: contain;
    object-fit: contain;
}

@media (max-width: 768px) {
    .feature-row {
        display: flex;
        height: 25vh;
        gap: 3rem;
        background-color: rgba(0, 0, 0, 0.7);
        margin-left: 10%;
        margin-right: 0%;
        margin-top: 10%;
        margin-bottom: 20%;
        width: 80%;
        align-items: center;
    }

    .feature-text {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        transition:
            transform 0.3s ease,
            opacity 0.3s ease;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.feature-text:hover {
    transform: scale(1.05);
    opacity: 0.8;
}

.feature-text:hover h3 {
    color: yellow;
}

.google-form-container {
    width: 100%;
    max-width: 700px;
    /* Adjust the max-width as needed */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.btn-primary {
    @apply font-medium text-sm inline-flex items-center justify-center px-3 py-2 border border-gray-200 rounded leading-5 shadow-sm transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 text-gray-600 w-full hover:bg-primaryGreen hover:text-white hover:border-white;
}

.btn-primary-hover {
    @apply font-medium text-sm inline-flex items-center justify-center px-3 py-2 border rounded leading-5 shadow-sm transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 w-full bg-primaryGreen text-white border-white hover:bg-primaryGreen/80;
}

.background-video {
    @apply absolute top-0 left-0 w-full h-full;
}

/* swiper css */
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    color: lightgray;
    font-weight: 800;
}

/* feature swiper css */
.feature-swiper .swiper-container {
    position: relative;
}

.feature-swiper .swiper-slide {
    transform: scale(0.8) !important;
    transition: all 300ms linear ease;
}

.feature-swiper .swiper-slide.swiper-slide-active {
    transform: scale(1) !important;
    transition: all 300ms linear ease;
}

/* react select */
.language-select input {
    outline: none !important;
    box-shadow: none !important;
}

.rightPane-title:hover {
    overflow: visible;
}

.rightPane-title span:hover {
    -webkit-animation-name: glow;
    animation-name: glow;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.video-responsive {
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@-webkit-keyframes glow {
    from {
        text-shadow:
            0px 0px 10px #fff,
            0px 0px 10px #614ad3;
    }

    to {
        text-shadow:
            0px 0px 10px #fff,
            0px 0px 10px #614ad3;
    }
}

@keyframes glow {
    from {
        text-shadow:
            0px 0px 10px #fff,
            0px 0px 10px #614ad3;
    }

    to {
        text-shadow:
            0px 0px 10px #fff,
            0px 0px 10px #614ad3;
    }
}

@media (max-width: 768px) {
    /* body{
    background-image: url("./assets/images/backgrounds/webp/intertennis_portrait_darkbg.webp");
  } */
    body:before {
        background: url('./assets/images/backgrounds/webp/player_racket_dark.webp')
            no-repeat center center;
        background-size: cover !important;
    }
}
